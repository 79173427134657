var $ = require("jquery");
window.jQuery = $;

import Cookies from 'js-cookie';
window.Cookies = Cookies;

const fancybox = require('@fancyapps/fancybox');

var Flickity = require('flickity');
require('flickity-bg-lazyload');
require('flickity-imagesloaded');
require('flickity-fade');
window.Flickity = Flickity;

var autocomplete = require('jquery-ui/ui/widgets/autocomplete');

import LazyLoad from "vanilla-lazyload";
const initLazyLoad = () => {
    var lazyLoadInstance = new LazyLoad({
        // Your custom settings go here
    });
}
window.initLazyLoad = initLazyLoad;

//----------------------------------------------------------------------------
//Disable transitions by default, reinstate once page is ready
//----------------------------------------------------------------------------
function styleYank() {
    window.document.body.classList.remove('disable-transitions');
}

if (window.document.readyState === 'complete') {
    styleYank();
} else {
    window.addEventListener('load', styleYank);
}



//----------------------------------------------------------------------------
//Alert banner
//----------------------------------------------------------------------------
$(document).on('click', '.js-alert-banner-dismiss', function (ev) {
    var cookieKey = $(this).data('cookie')
    Cookies.set(cookieKey, 1, {
        expires: 3
    });
    $(this).closest('.js-alert-banner').remove();
});

//----------------------------------------------------------------------------
//Recaptcha
//----------------------------------------------------------------------------
if ($('.g-recaptcha').length > 0) {

    var jsUrl = `//www.google.com/recaptcha/api.js?onload=callbackRecaptcha&render=explicit`;
    var jsScript = document.createElement('script');
    jsScript.async = true;
    jsScript.src = jsUrl;
    document.querySelector('head').appendChild(jsScript);

    const callbackRecaptcha = () => {
        $.each($('.g-recaptcha'), function (idx, itm) {
            grecaptcha.render($(itm).attr('id'), {
                'sitekey': $(itm).data('sitekey'),
                'theme': 'light'
            });
        });
    };
    window.callbackRecaptcha = callbackRecaptcha;
}


//----------------------------------------------------------------------------
//Lazy load
//----------------------------------------------------------------------------
initLazyLoad();


//----------------------------------------------------------------------------
//Main nav toggle
//----------------------------------------------------------------------------
$(".js-mainnav-open").on('click', function (e) {
    $(this).toggleClass('active');
    $('body').toggleClass('mainnav-active');
    $('.js-mainnav-close').focus();
    return false;
});

$(".js-mainnav-close").on('click', function (e) {
    $(this).toggleClass('active');
    $('body').removeClass('mainnav-active');
    $('.js-mainnav-open').removeClass('active');
    return false;
});

$(".js-mainnav-overlay").on('click', function (e) {
    $(this).removeClass('active');
    $('body').removeClass('mainnav-active');
    $('.js-mainnav').removeClass('active');
    $('.js-mainnav-open').removeClass('active');
    $('.js-nav-toggle').removeClass('active');
    return false;
});


//----------------------------------------------------------------------------
//Header scroll
//----------------------------------------------------------------------------
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('.js-site-header').outerHeight();

$(window).scroll(function (event) {
    didScroll = true;
    var lastScrollTop = $(window).scrollTop();

    if (lastScrollTop < 250) {
        $('.js-site-header').removeClass('scrolling');
    } else {
        $('.js-site-header').addClass('scrolling');
    }
});

setInterval(function () {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function hasScrolled() {
    var st = $(window).scrollTop();

    if (Math.abs(lastScrollTop - st) <= delta) {
        return;
    }

    if (st > lastScrollTop && st > navbarHeight) {
        // Scroll Down
        $('body').removeClass('nav-down').addClass('nav-up');

    } else {
        // Scroll Up
        if (st + $(window).height() < $(document).height()) {
            $('body').removeClass('nav-up').addClass('nav-down');
        }
    }
    lastScrollTop = st;
}

//----------------------------------------------------------------------------
//Main nav - level 2 toggle
//----------------------------------------------------------------------------
$(document).on('click', '.js-main-nav-level2-toggle', function (ev) {
    ev.preventDefault();
    ev.stopPropagation();

    const $this = $(this);
    const $nav = $this.parent().find('.js-mainnav-level2');
    $('.js-mainnav-level2').not($nav).removeClass('open');

    $('.js-mainnav-level2-toggle').not($this).removeClass('active');
    $this.toggleClass('active');
    $nav.toggleClass('open');
});

//----------------------------------------------------------------------------
//Proportional iframe embed
//----------------------------------------------------------------------------
$(".wysiwyg iframe").wrap("<div class='wysiwyg-iframe'/>");

//----------------------------------------------------------------------------
//Detail / summary - close all others on page
//----------------------------------------------------------------------------
const details = document.querySelectorAll("details");
details.forEach((targetDetail) => {
    targetDetail.addEventListener("click", () => {
        details.forEach((detail) => {
            if (detail !== targetDetail) {
                detail.removeAttribute("open");
            }
        });
    });
});




//----------------------------------------------------------------------------
//Copy URL link
//----------------------------------------------------------------------------
$(document).on("click", ".js-copy-url", function () {
    var copyText = document.getElementById("js-copy-url-val");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    $(".js-copy-message").addClass("active");
    setTimeout(function () {
        $(".js-copy-message").removeClass("active")
    }, 1000);
});
